import React, { useState } from "react"
import "./layout.css"

import MainHeader from "./main_header/main_header"
import MainFooter from "./footer/main_footer"
import loadable from "@loadable/component"
const Banner = loadable(() => import("./banner/banner"), {
  fallback: <div>Loading...</div>,
})
const Trainers = loadable(() => import("./trainers/trainers"), {
  fallback: <div>Loading...</div>,
})
const Trainings = loadable(() => import("./trainings/trainings"), {
  fallback: <div></div>,
})
const Home = loadable(() => import("./home/home"), {
  fallback: <div></div>,
})

const Layout = ({ children }) => {
  return (
    <>
      <MainHeader />
      <Banner />
      <Home />
      <Trainers />
      <Trainings />
      <MainFooter />
    </>
  )
}
export default Layout
