import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <>
    <Seo
      title="Home"
      description="The Leading Firm For Organization Skills.Best Training consutancy in Pakistan,U.K,UAE. Pre-populated training plans for 100+ jobs. AI based TNA "
      image="https://blogs.mycareerdreams.com/blogs/tips_header.webp"
      type="article"
    />
    <Layout />
  </>
)

export default IndexPage
